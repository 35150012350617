import type { ContextCommunityFragment } from '@aurora/shared-generated/types/graphql-types';
import { AccessFunctionType } from '@aurora/shared-types/redirects/pageRedirect';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import type { AppContextCommunityAdminPolicyFragment } from '../types/graphql-types';

/**
 * Determines whether the user can access the maintenance page
 *
 * @param accessFunctionType the access function type
 * @param communityContext the community context
 *
 * @author Matt Vrydaghs
 */
export default function useMaintenancePageAccess(
  accessFunctionType: AccessFunctionType,
  communityContext: ContextCommunityFragment & AppContextCommunityAdminPolicyFragment
): {
  isMaintenancePageAccessReady: boolean;
  canAccessMaintenancePage: boolean;
  canAdminCommunity: boolean;
} {
  const isMaintenancePage: boolean = accessFunctionType === AccessFunctionType.MAINTENANCE_PAGE;
  const maintenanceEnabled =
    communityContext?.maintenanceProperties?.isMaintenanceModeEnabled ?? false;
  const canAdminCommunity: boolean = checkPolicy(communityContext.communityPolicies?.canAdminNode);

  if (!communityContext) {
    return {
      isMaintenancePageAccessReady: true,
      canAccessMaintenancePage: false,
      canAdminCommunity
    };
  }

  if (isMaintenancePage) {
    return {
      isMaintenancePageAccessReady: !!communityContext.communityPolicies,
      canAccessMaintenancePage: canAdminCommunity || maintenanceEnabled,
      canAdminCommunity
    };
  }

  return {
    isMaintenancePageAccessReady: true,
    canAccessMaintenancePage: false,
    canAdminCommunity
  };
}
