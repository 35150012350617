import type { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { evictLocalOverrides } from '@aurora/shared-apollo/apolloCacheHelper';
import { buildPrefixedTextBundleId } from '@aurora/shared-apollo/helpers/ApolloTextCacheHelper';
import cachedTextQuery from '@aurora/shared-apollo/resolvers/text/CachedText.query.graphql';
import type {
  CachedTextQuery,
  CachedTextQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { LastModifiedKeys } from '@aurora/shared-types/assets';
import { useContext } from 'react';
import { isCustomizationBranch } from '@aurora/shared-utils/helpers/developer/SwitchBranchHelper';
import SwitchBranchContext from './context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from './context/TenantContext';
import useCachedFragment from './useCachedFragment';
import useLocale from './useLocale';

interface TextResponse {
  /**
   * Text data
   */
  data: Record<string, string>;
  /**
   * whether it is loading or not
   */
  loading: boolean;
  /**
   * error
   */
  error?: ApolloError;

  /**
   * Refetch the text
   *
   * @param locale the locale
   */
  refetch: (locale: string) => Promise<ApolloQueryResult<CachedTextQuery>>;
}

/**
 * Makes request to get the text for the specified namespace if not present in cached bundle
 * @param namespace namespace of the component
 * @param lastModified last modified date
 * @author Manish Shrestha
 */
export default function useText(namespace: string, lastModified?: string): TextResponse {
  const tenant = useContext(TenantContext);
  const locale = useLocale();
  const { branchName } = useContext(SwitchBranchContext);
  const skipCache = isCustomizationBranch(tenant, branchName);
  const bundleId = buildPrefixedTextBundleId(locale, namespace, lastModified);
  const cachedBundle = useCachedFragment(bundleId);

  const namespaces = [namespace];
  const cachedTextResult = useQuery<CachedTextQuery, CachedTextQueryVariables>(cachedTextQuery, {
    variables: {
      locale,
      namespaces,
      lastModified: skipCache ? LastModifiedKeys.BYPASS_OVERRIDE_CACHE : lastModified
    },
    skip: !skipCache && !!cachedBundle,
    context: { tenant },
    fetchPolicy: skipCache ? 'no-cache' : undefined
  });
  const { loading, data, error, client } = cachedTextResult;

  async function refetch(localOverride: string) {
    return cachedTextResult.refetch({ locale: localOverride, namespaces, lastModified });
  }

  if (!skipCache && cachedBundle) {
    return {
      data: cachedBundle.value ?? {},
      loading: false,
      refetch
    };
  } else {
    // if it's a local override, make sure it's not cached regardless of fetch policy
    if (!skipCache && !loading && data?.cachedText?.length > 0) {
      for (const cachedTextElement of data.cachedText) {
        evictLocalOverrides(client, cachedTextElement);
      }
    }
    return {
      data: data?.cachedText?.[0]?.value ?? {},
      loading,
      error,
      refetch
    };
  }
}
