import React from 'react';

export interface MaintenanceContextInterface {
  isMaintenanceModeEnabled: boolean;
}

const MaintenanceContext = React.createContext<MaintenanceContextInterface>({
  isMaintenanceModeEnabled: false
});

export default MaintenanceContext;
