import { getLog } from '@aurora/shared-utils/log';
import { useCallback, useContext, useEffect, useRef } from 'react';
import AppTypeContext from './context/AppTypeContext';
import RedirectContext from './context/RedirectContext/RedirectContext';
import type { RouteWithQuery } from '../routes/useCustomRouter';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { AdminPages, AdminQueryParams } from '../routes/adminRoutes';
import useRoutesForCurrentApp from '../routes/useRoutesForCurrentApp';
import type { BaseRouteAndParams } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import { canUseDOM } from 'exenv';

export enum RedirectStatusCode {
  /**
   * Whether the page is moved permanently
   */
  MOVED_PERMANENTLY = 301,
  /**
   * Whether the page is moved temporarily
   */
  MOVED_TEMPORARILY = 302
}

const log = getLog(module);

/**
 * Hook that supports redirecting either on the server-side during initial rendering of the
 * page, or on the client-side when navigating pages from the browser.
 *
 * @param shouldRedirect whether to redirect
 * @param routeWithQuery the route, params, and query params to use when redirecting
 * @param statusCode the status code used when redirecting
 * @param noCache whether the route data should be stored in browser cache
 *
 * @author Adam Ayres
 */
export default function useIsomorphicRedirect<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
>(
  shouldRedirect: boolean,
  routeWithQuery: RouteWithQuery<RouteType, UrlQueryParamType>,
  statusCode?: RedirectStatusCode,
  noCache?: boolean
): void {
  const { setRedirect } = useContext(RedirectContext);
  const app = useContext(AppTypeContext);
  const { router } = useRoutesForCurrentApp(app);
  const { route, query, params } = routeWithQuery;
  const redirecting = useRef(false);

  let redirectUrl;

  if (shouldRedirect) {
    redirectUrl = router.getRelativeUrlForRoute<BaseRouteAndParams<RouteType>>(
      route,
      params,
      query
    );
  }

  /**
   * Check whether the redirect route matches the current page URL
   */
  const onTargetPage = useCallback(() => {
    return router.doesRouteMatchCurrentPath<BaseRouteAndParams<RouteType>>(route, params, query);
  }, [params, query, route, router]);

  /**
   * For client-side renderings, we use the effect hook to redirect the page.
   */
  useEffect(() => {
    if (canUseDOM && redirectUrl && !redirecting.current && !onTargetPage()) {
      redirecting.current = true;

      router
        .pushRoute<BaseRouteAndParams<RouteType>>(route, params, query)
        .finally(() => {
          redirecting.current = false;
        })
        .catch(() => null);
    }
  }, [onTargetPage, params, query, redirectUrl, route, router, shouldRedirect]);

  /**
   * On the server-side, we use a callback on the `RedirectContext` to set the
   * redirect info. This is then consumed in the `getInitialProps` in `_app` which
   * supports redirecting for server-side renderings.
   */
  if (!canUseDOM && redirectUrl && !onTargetPage()) {
    log.debug('Redirecting from %O to %s', routeWithQuery, redirectUrl);
    setRedirect(redirectUrl, statusCode, noCache);
  }
}
