import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import IconTypes from '@aurora/shared-client/public/static/graphics/processed/enums';
import React from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import type { DropdownItemProps } from 'react-bootstrap/lib/esm/DropdownItem';
import localStyles from './FloatingControlBox.module.pcss';

export type FloatingControlDropdownItems = {
  /**
   * Click event for the dropdown item.
   */
  onClick: React.MouseEventHandler<DropdownItemProps>;

  /**
   * Title for the dropdown item.
   */
  title: string | React.ReactElement;

  /**
   * A unique ID for the dropdown menu item
   */
  id: string;
};

interface Props {
  /**
   * ID used for testing.
   */
  testId: string;

  /**
   * Class name(s) to apply to the wrapping element.
   */
  className?: string;

  /**
   * Id for the dropdown item.
   */
  dropdownId?: string;

  /**
   * Label for the dropdown toggle used for accessibility.
   */
  dropdownToggleLabel?: string;

  /**
   * Array of dropdown items.
   */
  dropdownMenuItems?: FloatingControlDropdownItems[];
}

/**
 * Floating control box that pops up in the lower left corner of the page.
 *
 * @author Willi Hyde, Andre Almeida
 */
const FloatingControlBox: React.FC<React.PropsWithChildren<Props>> = ({
  testId,
  className,
  children,
  dropdownId,
  dropdownToggleLabel,
  dropdownMenuItems
}) => {
  const cx = useClassNameMapper(localStyles);

  return (
    <div data-testid={testId} className={cx(className, 'lia-box')}>
      {children}
      {dropdownMenuItems?.length > 0 && (
        <Dropdown className={cx('lia-g-ml-20')}>
          <Dropdown.Toggle
            id={dropdownId}
            as={Button}
            data-testid={`FloatingControlBox.Dropdown.Toggle.${dropdownId}`}
            aria-label={dropdownToggleLabel}
            className={cx('lia-g-icon-btn')}
            variant={ButtonVariant.NO_VARIANT}
          >
            <Icon icon={IconTypes.GearIcon} size={IconSize.PX_20} color={IconColor.GRAY_900} />
          </Dropdown.Toggle>
          <Dropdown.Menu
            aria-labelledby={dropdownId}
            popperConfig={dropdownPopperConfig}
            align="right"
          >
            {dropdownMenuItems.map(({ id, title, onClick }) => {
              return (
                <Dropdown.Item
                  as={Button}
                  key={id}
                  data-testid={'FloatingControlBox.Dropdown.Item'}
                  variant={ButtonVariant.NO_VARIANT}
                  onClick={event => onClick(event)}
                  className={cx('lia-dropdown-item')}
                >
                  {title}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default FloatingControlBox;
