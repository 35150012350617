import React from 'react';
import FloatingControlBox from '../common/FloatingControlBox/FloatingControlBox';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../useTranslation';
import localStyles from './MaintenanceFloatingBox.module.pcss';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import IconTypes from '@aurora/shared-client/public/static/graphics/processed/enums';

/**
 * If maintenance mode is on, this displays a message to Admins letting them know that the community is in maintenance mode
 *
 * @author Matt Vrydaghs
 */
const MaintenanceFloatingBox: React.FC<React.PropsWithChildren<{}>> = () => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MAINTENANCE_FLOATING_BOX
  );
  if (textLoading) {
    return null;
  }
  return (
    <FloatingControlBox testId="MaintenanceFloatingBox">
      <div className={cx('lia-maintenance-icon lia-pulse')}>
        <Icon icon={IconTypes.DangerIcon} size={IconSize.PX_24} className={cx('lia-danger-icon')} />
      </div>
      <div className={cx('lia-maintenance-message')}>{formatMessage('description')}</div>
    </FloatingControlBox>
  );
};

export default MaintenanceFloatingBox;
