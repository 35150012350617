import React, { useContext } from 'react';
import type { MaintenanceContextInterface } from './MaintenanceContext';
import MaintenanceContext from './MaintenanceContext';
import MaintenanceFloatingBox from './MaintenanceFloatingBox';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import { EndUserPages } from '@aurora/shared-types/pages/enums';

const MaintenanceContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { community, pageTemplateContext } = useContext(AppContext);
  const { maintenanceProperties: { isMaintenanceModeEnabled = false } = {} } = community;
  const context: MaintenanceContextInterface = {
    isMaintenanceModeEnabled
  };

  return (
    <MaintenanceContext.Provider value={context}>
      {isMaintenanceModeEnabled &&
        pageTemplateContext.name !== EndUserPages.MaintenancePage &&
        pageTemplateContext.name !== EndUserPages.LoginPage && <MaintenanceFloatingBox />}
      {children}
    </MaintenanceContext.Provider>
  );
};

export default MaintenanceContextProvider;
