import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type {
  BaseRouteAndParams,
  Route as NextRoute,
  UrlQuery
} from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import type Routes from '@aurora/shared-utils/helpers/urls/NextRoutes/Routes';
import type { NextRouter, PrefetchOptions } from 'next/dist/shared/lib/router/router';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useContext } from 'react';
import { AppType } from '@aurora/shared-types/app';
import AppTypeContext from '../components/context/AppTypeContext';
import {
  createFullyQualifiedUrl,
  isHostLocal
} from '../helpers/router/CrossApplicationRouterHelper';
import type { AdminPages, AdminQueryParams } from './adminRoutes';
import type { CustomLinkProps } from './buildCustomLink';
import PagePathContext from '../components/context/PagePathContext/PagePathContext';
import { canUseDOM } from 'exenv';
import TenantContext from '../components/context/TenantContext';

/**
 * Copy from `TransitionOptions` in NextJs.
 */
export interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  /**
   * Perform a "hard" (full) page redirect.
   */
  hard?: boolean;
}

/**
 * Trick to force using a TS type when calling a method that has a generic type.
 */
type Force<Type, FakeType, Warning, Actual = Type> = Type extends FakeType ? Warning : Actual;

/**
 * Force a TS warning when using router methods that use a generic type to get the params for a route.
 * This type is used as the default and then checked using a conditional TS type throwing a TS warning
 * when the default type is used - thus enforcing an explicit type be passed.
 */
export interface ForceWarning<RouteType> extends BaseRouteAndParams<RouteType> {
  route: RouteType;
  params: {
    FORCE_USE_TS: string;
  };
}

/**
 * Force a TS warning when using router methods that require params for a given page.
 */
export type ForceRouteName<RouteType, RouteAndParams extends BaseRouteAndParams<RouteType>> = Force<
  RouteAndParams,
  ForceWarning<RouteType>,
  'You must provide a type parameter',
  RouteAndParams['route']
>;

export interface RouteWithQuery<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
> extends BaseRouteAndParams<RouteType> {
  /**
   * The query params.
   */
  query?: Partial<UrlQuery<UrlQueryParamType>>;
}

export interface RouteWithOptions<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
> extends RouteWithQuery<RouteType, UrlQueryParamType> {
  /**
   * The route transition options
   */
  options?: TransitionOptions;
}

export interface RouterAndLink<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
> {
  /**
   * A wrapper around the NextJs Router with support for custom routing approach.
   */
  router: CustomRouter<RouteType, UrlQueryParamType>;

  /**
   * A wrapper around the NextJs Link with support for custom routing approach.
   */
  Link: <Route extends BaseRouteAndParams<RouteType>>(
    props: CustomLinkProps<RouteType, Route> & { children?: ReactNode }
  ) => JSX.Element;
}

/**
 * A query key and value
 */
export interface QueryParamKeyValue<UrlQueryParamType> {
  /**
   * The query param key
   */
  key: UrlQueryParamType;
  /**
   * The query param value
   */
  value: string | string[];
}

export interface CustomRouter<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
> extends Omit<NextRouter, 'push' | 'replace' | 'prefetch'> {
  /**
   * Wrapper around NextJs `Router.push` that accepts a route
   *
   * @param routeName the route name
   * @param parameters? the route path parameters
   * @param query? the route query parameters
   * @param options? transition options
   */
  pushRoute<RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>>(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters?: RouteAndParams['params'],
    query?: Partial<UrlQuery<UrlQueryParamType>>,
    options?: TransitionOptions
  ): Promise<boolean>;

  /**
   * Wrapper around NextJs `Router.replace` that accepts a route
   *
   * @param routeName the route name
   * @param parameters the route parameters
   * @param query? the route query parameters
   * @param options transition options
   */
  replaceRoute<RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>>(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters?: RouteAndParams['params'],
    query?: Partial<UrlQuery<UrlQueryParamType>>,
    options?: TransitionOptions
  ): Promise<boolean>;

  /**
   * Wrapper around NextJs `Router.prefetch` that accepts a route
   *
   * @param routeName the route name
   * @param parameters the route parameters
   * @param query? the route query parameters
   * @param options prefetch options
   */
  prefetchRoute<RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>>(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters?: RouteAndParams['params'],
    query?: Partial<UrlQuery<UrlQueryParamType>>,
    options?: PrefetchOptions
  ): Promise<void>;

  /**
   * Gets a relative URL for a given route and parameters. This path is not a fully qualified URL. It includes
   * the basePath.
   *
   * @param routeName the route name
   * @param parameters the route parameters
   * @param query? the route query parameters
   */
  getRelativeUrlForRoute<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters: RouteAndParams['params'],
    query?: Partial<UrlQuery<UrlQueryParamType>>
  ): string | null;

  /**
   * Whether the specified route matches the current path URL
   *
   * @param routeName the route name
   * @param parameters the route parameters
   * @param query? the route query parameters
   */
  doesRouteMatchCurrentPath<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters: RouteAndParams['params'],
    query?: Partial<UrlQuery<UrlQueryParamType>>
  ): boolean;

  /**
   * Adds a query param to the current route.
   *
   * @param key the query param key
   * @param value the query param value
   * @param shallow whether the change should be considered shallow (see NextJs docs)
   * @param recordHistory whether the change should be captured in the History API, if true then
   * the back button will go back to this state.
   */
  addQueryParam(
    key: UrlQueryParamType,
    value: string,
    shallow?: boolean,
    recordHistory?: boolean
  ): Promise<boolean>;

  /**
   * Adds query params to the current route.
   *
   * @param queryParams the query params to add
   * @param shallow whether the change should be considered shallow (see NextJs docs)
   * @param recordHistory whether the change should be captured in the History API, if true then
   * the back button will go back to this state.
   */
  addQueryParams(
    queryParams: QueryParamKeyValue<UrlQueryParamType>[],
    shallow?: boolean,
    recordHistory?: boolean
  ): Promise<boolean>;

  /**
   * Removes a query param from the current route.
   *
   * @param keys any array of query param keys to remove key/value pairs for
   * @param shallow whether the change should be considered shallow (see NextJs docs)
   * @param recordHistory whether the change should be captured in the History API, if true then
   * the back button will go back to this state.
   */
  removeQueryParam(
    keys: UrlQueryParamType[],
    shallow?: boolean,
    recordHistory?: boolean
  ): Promise<boolean>;

  /**
   * Returns the path without the query params
   */
  getPathWithoutQueryStrings(): string;

  /**
   * Get a path param by its key.
   *
   * @param key the path param key.
   * @param defaultValue a default value to use if the param is unspecified.
   */
  getPathParam<RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>>(
    key: keyof RouteAndParams['params'],
    defaultValue?: string
  ): string | null;

  /**
   * Get all params for the current route, this does not include query params.
   */
  getPathParams<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(): RouteAndParams['params'];

  /**
   * Get a query param by its key.
   *
   * @param key the query param key.
   * @param defaultValue a default value to use if the param is unspecified.
   */
  getQueryParam(key: UrlQueryParamType, defaultValue?: string): string | string[] | null;

  /**
   * Get all Query params for the current route, this does not include path params.
   */
  getQueryParams(): Partial<UrlQuery<UrlQueryParamType>>;

  /**
   * Gets a query param by its key and provides the first one in the case there are
   * multiple values for a given query param key.
   *
   * @param key the query param key.
   * @param defaultValue a default value to use if the param is unspecified.
   */
  getUnwrappedQueryParam(key: UrlQueryParamType, defaultValue?: string): string | null;

  /**
   * Gets a query param by its key and provides an array of values if there are
   * multiple values for a given query param key.
   *
   * @param key the query param key.
   * @param defaultValue a default value to use if the param is unspecified.
   */
  getWrappedQueryParam(key: UrlQueryParamType, defaultValue?: string[]): string[] | null;

  /**
   * Get a route with the path and query params.
   *
   * @param path the path
   */
  getRouteAndParamsByPath(
    path: string
  ): Omit<RouteWithOptions<RouteType, UrlQueryParamType>, 'options'>;

  /**
   * Get the current route and params.
   */
  getCurrentRouteAndParams(): RouteWithOptions<RouteType, UrlQueryParamType>;

  /**
   * Get the current page name.
   */
  getCurrentPageName(): RouteType | null;

  /**
   * Get a route by its name.
   *
   * @param routeName the route name
   */
  getRoute(routeName: RouteType): NextRoute<RouteType, BaseRouteAndParams<RouteType>> | undefined;

  /**
   * Aborts a route change using workaround noted here:
   *
   * https://github.com/vercel/next.js/issues/2476#issuecomment-563190607
   */
  abort(): void;

  /**
   * The current path, including query params.
   */
  path: string;
}

export default function useCustomRouter<
  RouteType extends EndUserPages | AdminPages,
  UrlQueryParamType extends EndUserQueryParams | AdminQueryParams
>(
  routes: Routes<RouteType>,
  targetApp: AppType = AppType.END_USER
): CustomRouter<RouteType, UrlQueryParamType> {
  const router = useRouter();
  const { push, replace, prefetch, asPath, ...remainingRouter } = router;
  // This fallback should never be hit, but in case it does get hit, it should
  // account for the basePath if one is configured for the community
  const initialSsrPath = useContext(PagePathContext) ?? '/';
  const tenant = useContext(TenantContext);

  let path;

  if (canUseDOM) {
    // LIA-96128 The client side rendering of the CommunityPage, when there is a base path, does not end
    // in a trailing slash, which is inconsistent with the server side rendering. This causes the route
    // pattern matching logic to fail for the CommunityPage. This is a workaround to ensure that the path
    // always ends in a trailing slash when there is a base path and the route is for the CommunityPage.
    if (asPath === `/${tenant?.basePath}` && !asPath.endsWith('/')) {
      path = `/${tenant?.basePath}/`;
    } else {
      path = asPath;
    }
  } else {
    path = initialSsrPath;
  }

  const currentApp = useContext(AppTypeContext);

  function pushRoute<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters: RouteAndParams['params'],
    query?: Partial<UrlQuery<UrlQueryParamType>>,
    options?: TransitionOptions
  ): Promise<boolean> {
    const { externalRelativeUrl, internalRelativeUrl } = routes.findAndGetUrls<RouteAndParams>(
      routeName,
      parameters,
      query
    );
    if (options?.hard || (targetApp !== currentApp && isHostLocal(window.location.host))) {
      // if the target app does not match the current app in the local environment we are directing to an external
      // site, so change the page using window.location.href
      window.location.href = createFullyQualifiedUrl(
        window.location.host,
        externalRelativeUrl,
        targetApp
      );
      return Promise.resolve(true);
    }
    // if the target app matches the current app, use the regular push implementation from NextRouter#useRouter
    return push(internalRelativeUrl, externalRelativeUrl, options);
  }

  function replaceRoute<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters: RouteAndParams['params'],
    query?: Partial<UrlQuery<UrlQueryParamType>>,
    options?: TransitionOptions
  ): Promise<boolean> {
    const { externalRelativeUrl, internalRelativeUrl } = routes.findAndGetUrls(
      routeName,
      parameters,
      query
    );
    if (targetApp !== currentApp && isHostLocal(window.location.host)) {
      // if the target app does not match the current app in the local environment we are redirecting to an external
      // site, so change the page using window.location.replace, which does not record the page in session history
      window.location.replace(
        createFullyQualifiedUrl(window.location.host, externalRelativeUrl, targetApp)
      );
      return Promise.resolve(true);
    }
    // if the target app matches the current app, use the regular replace implementation from NextRouter#useRouter
    return replace(internalRelativeUrl, externalRelativeUrl, options);
  }

  function prefetchRoute<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters: RouteAndParams['params'],
    query?: Partial<UrlQuery<UrlQueryParamType>>,
    options?: PrefetchOptions
  ): Promise<void> {
    const { externalRelativeUrl, internalRelativeUrl } = routes.findAndGetUrls(
      routeName,
      parameters,
      query
    );
    return prefetch(internalRelativeUrl, externalRelativeUrl, options);
  }

  function getRelativeUrlForRoute<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters: RouteAndParams['params'] = {},
    query?: Partial<UrlQuery<UrlQueryParamType>>
  ) {
    return routes.findAndGetUrls(routeName, parameters, query)?.externalRelativeUrl ?? null;
  }

  function ensureTrailingSlash(url: string): string {
    return url.endsWith('/') ? url : `${url}/`;
  }

  function doesRouteMatchCurrentPath<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(
    routeName: ForceRouteName<RouteType, RouteAndParams>,
    parameters: RouteAndParams['params'] = {},
    query?: Partial<UrlQuery<UrlQueryParamType>>
  ) {
    const relativeUrlForRoute = getRelativeUrlForRoute(routeName, parameters, query);
    return relativeUrlForRoute?.endsWith('/')
      ? relativeUrlForRoute === ensureTrailingSlash(path)
      : relativeUrlForRoute === path;
  }

  function addQueryParams(
    queryParams: QueryParamKeyValue<UrlQueryParamType>[],
    shallow = true,
    recordHistory = false
  ): Promise<boolean> {
    const method = recordHistory ? pushRoute : replaceRoute;
    const { route, params, query } = routes.getRouteAndParamsByPath(path);
    if (route) {
      queryParams.forEach(({ key, value }) => {
        query[key] = value;
      });

      return method<BaseRouteAndParams<RouteType>>(route.name, params, query, { shallow });
    }
    return Promise.reject();
  }

  function addQueryParam(
    key: UrlQueryParamType,
    value: string | string[],
    shallow = true,
    recordHistory = false
  ): Promise<boolean> {
    return addQueryParams([{ key, value }], shallow, recordHistory);
  }

  function removeQueryParam(
    keys: UrlQueryParamType[],
    shallow = true,
    recordHistory = false
  ): Promise<boolean> {
    const method = recordHistory ? pushRoute : replaceRoute;
    const { route, params, query } = routes.getRouteAndParamsByPath(path);
    if (route) {
      keys.forEach(key => {
        delete query[key];
      });
      return method<BaseRouteAndParams<RouteType>>(route.name, params, query, { shallow });
    }
    return Promise.reject();
  }

  function getPathWithoutQueryStrings(): string {
    return path.split('?')[0];
  }

  function getPathParam<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(key: keyof RouteAndParams['params'], defaultValue: string | null = null): string | null {
    const { params } = routes.getRouteAndParamsByPath(path);
    return params[key as number | string]?.toString() ?? defaultValue;
  }

  function getPathParams<
    RouteAndParams extends BaseRouteAndParams<RouteType> = ForceWarning<RouteType>
  >(): RouteAndParams['params'] {
    return routes.getRouteAndParamsByPath(path).params;
  }

  function getQueryParam(
    key: UrlQueryParamType,
    defaultValue: string | null = null
  ): string | string[] | null {
    const { query } = routes.getRouteAndParamsByPath(path);
    return query[key] ?? defaultValue;
  }

  function getQueryParams(): Partial<UrlQuery<UrlQueryParamType>> {
    return routes.getRouteAndParamsByPath(path).query;
  }

  function getUnwrappedQueryParam(
    key: UrlQueryParamType,
    defaultValue: string | null = null
  ): string | null {
    const { query } = routes.getRouteAndParamsByPath(path);
    const value = query[key];
    if (Array.isArray(value)) {
      return value[0] ?? defaultValue;
    }
    return value ?? defaultValue;
  }

  function getWrappedQueryParam(
    key: UrlQueryParamType,
    defaultValue: string[] | null = null
  ): string[] | null {
    const { query } = routes.getRouteAndParamsByPath(path);
    const value = query[key];
    if (Array.isArray(value)) {
      return value ?? defaultValue;
    } else {
      return value ? [value] : defaultValue;
    }
  }

  function getRouteAndParamsByPath(
    localPath: string
  ): RouteWithOptions<RouteType, UrlQueryParamType> {
    const { route, params, query } = routes.getRouteAndParamsByPath(localPath);
    return {
      route: route?.name as RouteType,
      params,
      query
    };
  }

  function getCurrentRouteAndParams(): RouteWithOptions<RouteType, UrlQueryParamType> {
    return getRouteAndParamsByPath(path);
  }

  function getCurrentPageName(): RouteType | null {
    return getCurrentRouteAndParams()?.route ?? null;
  }

  function getRoute(
    routeName: RouteType
  ): NextRoute<RouteType, BaseRouteAndParams<RouteType>> | undefined {
    return routes.getRouteByName(routeName);
  }

  function abort() {
    router.events.emit('routeChangeError', { cancelled: 'RouteChange aborted' }, router.asPath, {
      shallow: true
    });
    const { route, params, query } = getRouteAndParamsByPath(path);
    replaceRoute<BaseRouteAndParams<RouteType>>(route, params, query, { shallow: true });
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw 'RouteChange aborted';
  }

  return {
    ...remainingRouter,
    asPath: path,
    pushRoute,
    replaceRoute,
    prefetchRoute,
    getRelativeUrlForRoute,
    addQueryParam,
    addQueryParams,
    removeQueryParam,
    getPathWithoutQueryStrings,
    getPathParam,
    getPathParams,
    getQueryParam,
    getQueryParams,
    getUnwrappedQueryParam,
    getRouteAndParamsByPath,
    getCurrentRouteAndParams,
    getCurrentPageName,
    abort,
    getWrappedQueryParam,
    path,
    doesRouteMatchCurrentPath,
    getRoute
  };
}
