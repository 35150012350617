import { createContext } from 'react';
import type ToastProps from '../../common/ToastAlert/ToastAlertProps';

export interface ToastContextInterface {
  /**
   * Callback function used to add toasts.
   *
   * @callback
   * @param toast The toast to add.
   */
  addToast(toast: ToastProps): void;
  /**
   * Callback function used to remove toasts.
   *
   * @callback
   * @param toastId The ID of the toast to remove.
   */
  removeToast(toastId: ToastProps['id']): void;
  /**
   * Callback function used to add multiple toasts.
   *
   * @callback
   * @param toasts The toasts to add.
   */
  addToasts(toasts: ToastProps[]): void;
  /**
   * Callback function used to clear all toasts
   *
   * @callback
   */
  clearToasts(): void;

  /**
   * Callback used to add and remove toasts by id.
   *
   * @callback
   * @param toastsToAdd The toasts to add.
   * @param toastsToRemove The id of the toasts to remove.
   */
  addAndRemoveToasts(toastsToAdd: Array<ToastProps>, toastsToRemove: ToastProps['id'][]): void;
}

const ToastContext = createContext<ToastContextInterface>({
  addToast: () => null,
  removeToast: () => null,
  addToasts: () => null,
  clearToasts: () => null,
  addAndRemoveToasts: () => null
});

export default ToastContext;
