import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import type { RouteInfo } from '@aurora/shared-types/community';
import { useEffect, useRef } from 'react';

/**
 * This hook stores the previous route info in a global state
 *
 * @param pathInfo path info
 */
export default function usePreviousRouteInfo(pathInfo: RouteInfo): void {
  const [, setPreviousPathInfo] = useGlobalState<GlobalStateType>(
    GlobalStateType.PREVIOUS_ROUTE_INFO
  );

  const previousRouteInfoRef = useRef<RouteInfo | null>(null);

  useEffect(() => {
    if (pathInfo !== previousRouteInfoRef.current) {
      //if the route changes, fire an event for any custom Javascript listeners
      if (previousRouteInfoRef.current) {
        const detail = { incoming: pathInfo, previous: previousRouteInfoRef.current };
        global.dispatchEvent(
          new CustomEvent('kh-aurora:routeChange', {
            detail
          })
        );

        function dispatchRouteChangedEvent() {
          const actualDetail = { actual: pathInfo };
          global.dispatchEvent(
            new CustomEvent('kh-aurora:routeChanged', {
              detail: actualDetail
            })
          );
        }

        function handleDOMContentLoaded() {
          dispatchRouteChangedEvent();
          document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
        }

        if (document.readyState === 'loading') {
          document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
        } else {
          dispatchRouteChangedEvent();
        }
      }
      // Update previousPath in global state with the previous path
      setPreviousPathInfo(previousRouteInfoRef.current);
      // Update the ref to current path for next comparison
      previousRouteInfoRef.current = pathInfo;
    }
  }, [pathInfo, setPreviousPathInfo]);
}
