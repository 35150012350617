import type {
  FieldDefinition,
  PropDefinition,
  Scalars
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ComponentPageScope,
  FieldDataType,
  FieldInputControl
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import type { FormatMessageValues } from '@aurora/shared-types/texts';
import {
  capitalizeFirst,
  unCapitalizeFirst
} from '@aurora/shared-utils/helpers/objects/StringHelper';
import { getLog } from '@aurora/shared-utils/log';
import type React from 'react';
import type { ReactNode } from 'react';
import type { FieldName, FieldValues } from 'react-hook-form';
import type { AppContextInterface } from '../../components/context/AppContext/AppContext';
import type { CheckFieldSpec } from '../../components/form/CheckField/CheckField';
import type { DatetimeSpec } from '../../components/form/DateTimeEditorField/DateTimeEditorField';
import type { FormGroupAsElement } from '../../components/form/enums';
import {
  FormCheckInputType,
  FormFieldVariant,
  FormInputFieldInputType
} from '../../components/form/enums';
import type { InputFieldSpec } from '../../components/form/InputField/InputField';
import type { RadioFieldSpec } from '../../components/form/RadioField/RadioField';
import type { SelectFieldSpec } from '../../components/form/SelectField/SelectField';
import type {
  FormFieldType,
  FormGroup,
  FormLabel,
  PossibleValue
} from '../../components/form/types';
import { CreateCustomComponent } from '../../types/enums';

/**
 * Configuration form data for a component prop.
 */
export interface ComponentProp {
  /**
   * The prop name.
   */
  name: string;
  /**
   * The prop value.
   */
  value: string | number | boolean | Array<string> | Array<number>;
}

const CUSTOM_COMPONENT_FORM_ID_PREFIX = 'custom.widget.';

const log = getLog(module);

/**
 * Returns whether this form id is for a custom component.
 * @param formId the form id.
 */
export function isCustomComponent(formId: string): boolean {
  return formId.startsWith(CUSTOM_COMPONENT_FORM_ID_PREFIX);
}

/**
 * Returns a custom component id from the given form id, or null if the form id is not for a custom component.
 * @param formId the form id.
 */
export function getCustomComponentIdFromFormId(formId: string): string | null {
  return isCustomComponent(formId) ? formId : null;
}

/**
 * Converts a prop name to a namespaced prop name (adds the 'c' prefix).
 * @param propName the prop name to namespace.
 */
export function getNamespacedCustomFieldName(propName: string) {
  return `c${capitalizeFirst(propName)}`;
}

/**
 * Converts a namespaced prop name back to it's non-namespaced prop name (removes the 'c' prefix).
 * @param propName the prop name to un-namespace.
 */
export function getDeNamespacedCustomFieldName(propName: string) {
  return unCapitalizeFirst(propName.slice('c'.length));
}

/**
 * Returns whether the given prop name is a valid custom prop name.
 * Custom prop names must start with the character 'c', and have the first letter after the 'c' capitalized.
 * For example: 'cMessageId' is a valid custom field name, while 'messageId' is not.
 * @param propName the prop name to check.
 */
export function isCustomPropName(propName: string) {
  return (
    propName.startsWith('c') &&
    propName === getNamespacedCustomFieldName(getDeNamespacedCustomFieldName(propName))
  );
}

/**
 * Returns whether a prop value is a string or number
 * @param propValue the prop value to check.
 */
function isCustomPropStringOrNumber(propValue): propValue is string | number {
  const propType = typeof propValue;
  return propType === 'string' || propType === 'number';
}

/**
 * Returns whether a prop value is a valid custom prop value.
 * Custom props need to be either a string, a number, a boolean, a string array, or a number array.
 * @param propValue the prop value to check.
 */
export function isCustomPropValue(
  propValue
): propValue is string | number | boolean | Array<string> | Array<number> {
  if (Array.isArray(propValue)) {
    const objectArray: Array<unknown> = propValue as Array<unknown>;
    if (objectArray.length === 0) {
      return true;
    }
    return isCustomPropStringOrNumber(objectArray[0]);
  }
  return isCustomPropStringOrNumber(propValue) || typeof propValue === 'boolean';
}

/**
 * Converts a string to a string array, splitting the spring on the "," character.
 * @param value the string value to coerce to an array of strings.
 */
function coerceToStringArray(value: string): Array<string> {
  return value.split(',');
}

/**
 * Converts a string to a number array, splitting the spring on the "," character.
 * @param value the string value to coerce to an array of numbers.
 */
function coerceToNumberArray(value: string): Array<number> {
  return coerceToStringArray(value).map(Number);
}

/**
 * Converts a component default value to the appropriate typed value.
 * @param propDef the property definition.
 */
export function convertFieldDefaultValue(
  propDef: FieldDefinition
): string | number | boolean | string[] | number[] {
  const value = propDef.defaultValue;
  if (value == null) {
    return null;
  }
  switch (propDef.dataType) {
    case FieldDataType.Number: {
      return propDef.list ? coerceToNumberArray(value) : Number(value);
    }
    case FieldDataType.Boolean: {
      return Boolean(value);
    }
    case FieldDataType.String: {
      return propDef.list ? coerceToStringArray(value) : value;
    }
  }
}

/**
 * Converts an array of string possible values to an array of number possible values.
 * @param possibleValues the string possible values array to convert.
 */
function convertToStringArrayPossibleValues<FormDataT extends FieldValues>(
  possibleValues: Array<PossibleValue<string, FormDataT>>
): Array<PossibleValue<Array<string>, FormDataT>> {
  return possibleValues.map(possibleValue => {
    const { key, value, label, description } = possibleValue;
    return {
      key,
      value: coerceToStringArray(value),
      label,
      description
    };
  });
}

/**
 * Converts an array of string possible values to an array of number possible values.
 * @param possibleValues the string possible values array to convert.
 */
function convertToNumberArrayPossibleValues<FormDataT extends FieldValues>(
  possibleValues: Array<PossibleValue<string, FormDataT>>
): Array<PossibleValue<Array<number>, FormDataT>> {
  return possibleValues.map(possibleValue => {
    const { key, value, label, description } = possibleValue;
    return {
      key,
      value: coerceToNumberArray(value),
      label,
      description
    };
  });
}

/**
 * Converts an array of string possible values to an array of number possible values.
 * @param possibleValues the string possible values array to convert.
 * @param array whether the value is supposed to be an array.
 */
function convertToNumberPossibleValues<FormDataT extends FieldValues>(
  possibleValues: Array<PossibleValue<string, FormDataT>>,
  array: boolean
): Array<PossibleValue<number | Array<number>, FormDataT>> {
  return array
    ? convertToNumberArrayPossibleValues(possibleValues)
    : possibleValues.map(possibleValue => {
        const { key, value, label, description } = possibleValue;
        return {
          key,
          value: Number(value),
          label,
          description
        };
      });
}

/**
 * Returns a Datetime form field spec.
 * @param propName the prop name.
 * @param propValue the prop value.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function dateTimeFormFieldType(
  propName: string,
  propValue: string | number | null,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): DatetimeSpec<FieldName<FieldValues>, FieldValues> {
  return {
    name: propName,
    fieldVariant: FormFieldVariant.DATETIME,
    defaultValue: propValue,
    formGroupSpec
  };
}

/**
 * Returns a Select form field spec for a text-based field.
 * @param propName the prop name.
 * @param array whether the value is supposed to be an array.
 * @param propValue the prop value.
 * @param possibleValues the set of possible values that can be used.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function textSelectFormFieldType(
  propName: string,
  array: boolean,
  propValue: string | Array<string> | null,
  possibleValues?: Array<PossibleValue<string, FieldValues>> | null,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): SelectFieldSpec<FieldName<FieldValues>, FieldValues> {
  return {
    name: propName,
    fieldVariant: FormFieldVariant.SELECT,
    values:
      array && possibleValues != null
        ? convertToStringArrayPossibleValues(possibleValues)
        : possibleValues,
    defaultValue: propValue,
    formGroupSpec
  };
}

/**
 * Returns a Select form field spec for a number-based field.
 * @param propName the prop name.
 * @param array whether the value is supposed to be an array.
 * @param propValue the prop value.
 * @param possibleValues the set of possible values that can be used.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function numberSelectFormFieldType(
  propName: string,
  array: boolean,
  propValue: number | Array<number> | null,
  possibleValues?: Array<PossibleValue<string, FieldValues>> | null,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): SelectFieldSpec<FieldName<FieldValues>, FieldValues> {
  return {
    name: propName,
    fieldVariant: FormFieldVariant.SELECT,
    values: possibleValues == null ? null : convertToNumberPossibleValues(possibleValues, array),
    defaultValue: propValue,
    formGroupSpec
  };
}

/**
 * Returns a Radio form field spec for a text-based field.
 * @param propName the prop name.
 * @param array whether the value is supposed to be an array.
 * @param propValue the prop value.
 * @param possibleValues the set of possible values that can be used.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function textRadioFormFieldType(
  propName: string,
  array: boolean,
  propValue: string | Array<string> | null,
  possibleValues?: Array<PossibleValue<string, FieldValues>> | null,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): RadioFieldSpec<FieldName<FieldValues>, FieldValues> {
  return {
    name: propName,
    fieldVariant: FormFieldVariant.RADIO,
    values:
      array && possibleValues != null
        ? convertToStringArrayPossibleValues(possibleValues)
        : possibleValues,
    defaultValue: propValue,
    formGroupSpec
  };
}

/**
 * Returns a Radio form field spec for a number-based field.
 * @param propName the prop name.
 * @param array whether the value is supposed to be an array.
 * @param propValue the prop value.
 * @param possibleValues the set of possible values that can be used.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function numberRadioFormFieldType(
  propName: string,
  array: boolean,
  propValue: number | number[] | null,
  possibleValues?: Array<PossibleValue<string, FieldValues>> | null,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): RadioFieldSpec<FieldName<FieldValues>, FieldValues> {
  return {
    name: propName,
    fieldVariant: FormFieldVariant.RADIO,
    values: possibleValues == null ? null : convertToNumberPossibleValues(possibleValues, array),
    defaultValue: propValue,
    formGroupSpec
  };
}

/**
 * Returns a Radio form field spec for a boolean-based field.
 * @param propName the prop name.
 * @param propValue the prop value.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function booleanRadioFormFieldType(
  propName: string,
  propValue: boolean,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): RadioFieldSpec<FieldName<FieldValues>, FieldValues> {
  return {
    name: propName,
    fieldVariant: FormFieldVariant.RADIO,
    values: [
      {
        key: 'true',
        value: true
      },
      {
        key: 'false',
        value: false
      }
    ],
    defaultValue: propValue,
    formGroupSpec
  };
}

/**
 * Returns an input form field spec for a text-based field.
 * @param propName the prop name.
 * @param array whether the value is supposed to be an array.
 * @param propValue the prop value.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function textInputFormFieldType(
  propName: string,
  array: boolean,
  propValue: string | string[] | null,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): InputFieldSpec<FieldName<FieldValues>, FieldValues> {
  return {
    name: propName,
    fieldVariant: FormFieldVariant.INPUT,
    inputType: FormInputFieldInputType.TEXT,
    defaultValue: array && Array.isArray(propValue) ? [propValue].join(',') : propValue,
    formGroupSpec
  };
}

/**
 * Returns an input form field spec for a number-based field.
 * @param propName the prop name.
 * @param array whether the value is supposed to be an array.
 * @param propValue the prop value.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function numberInputFormFieldType(
  propName: string,
  array: boolean,
  propValue: number | number[] | null,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): InputFieldSpec<FieldName<FieldValues>, FieldValues> {
  return {
    name: propName,
    fieldVariant: FormFieldVariant.INPUT,
    inputType: array ? FormInputFieldInputType.TEXT : FormInputFieldInputType.NUMBER,
    defaultValue: array && Array.isArray(propValue) ? [propValue].join(',') : propValue,
    formGroupSpec
  };
}

/**
 * Returns a checkbox form field spec.
 * @param propName the prop name.
 * @param propValue the prop value.
 * @param label? an optional label to use for this field.
 * @param description? an optional description to use for this field.
 */
function checkboxFormFieldType(
  propName: string,
  propValue: boolean,
  label?: false | FormLabel<React.ElementType> | undefined,
  description?: true | string | React.ReactElement | undefined
): CheckFieldSpec<FieldName<FieldValues>, FieldValues> {
  return {
    name: propName,
    fieldVariant: FormFieldVariant.CHECK,
    inputType: FormCheckInputType.SWITCH,
    defaultValue: propValue ?? false,
    label:
      label && !(typeof label === 'boolean')
        ? (label as FormLabel).labelTextOverride
        : (label as ReactNode),
    description
  };
}

/**
 * Creates a form field type for a custom text-based form field.
 * @param propName the custom field prop name.
 * @param array whether the value is supposed to be an array.
 * @param control the input control specified for the custom field.
 * @param propValue the value of the field.
 * @param possibleValues possible values, if only certain values are allowed.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function getCustomStringFormFieldSpec(
  propName: string,
  control: FieldInputControl,
  array: boolean,
  propValue: string | Array<string> | null,
  possibleValues?: Array<PossibleValue<string, FieldValues>> | null,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldType<FieldName<FieldValues>, FieldValues, FormFieldVariant> {
  switch (control) {
    case FieldInputControl.Datetime: {
      if (!array && !Array.isArray(propValue)) {
        return dateTimeFormFieldType(propName, propValue ? String(propValue) : null, formGroupSpec);
      }
      break;
    }
    case FieldInputControl.Select: {
      if (possibleValues) {
        return textSelectFormFieldType(propName, array, propValue, possibleValues, formGroupSpec);
      }
      break;
    }
    case FieldInputControl.Radio: {
      if (possibleValues) {
        return textRadioFormFieldType(propName, array, propValue, possibleValues, formGroupSpec);
      }
      break;
    }
    case FieldInputControl.Input: {
      return textInputFormFieldType(propName, array, propValue, formGroupSpec);
    }
    default: {
      break;
    }
  }
  if (possibleValues) {
    if (possibleValues.length < 4) {
      return textRadioFormFieldType(propName, array, propValue, possibleValues, formGroupSpec);
    } else {
      return textSelectFormFieldType(propName, array, propValue, possibleValues, formGroupSpec);
    }
  }
  return textInputFormFieldType(propName, array, propValue, formGroupSpec);
}

/**
 * Creates a form field type for a custom number-based form field.
 * @param propName the custom field prop name.
 * @param array whether the value is supposed to be an array.
 * @param control the input control specified for the custom field.
 * @param propValue the value of the field.
 * @param possibleValues possible values, if only certain values are allowed.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function getCustomNumberFormFieldSpec(
  propName: string,
  array: boolean,
  control: FieldInputControl,
  propValue: number | Array<number> | null,
  possibleValues?: Array<PossibleValue<string, FieldValues>> | null,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldType<FieldName<FieldValues>, FieldValues, FormFieldVariant> {
  switch (control) {
    case FieldInputControl.Select: {
      if (possibleValues) {
        return numberSelectFormFieldType(propName, array, propValue, possibleValues, formGroupSpec);
      }
      break;
    }
    case FieldInputControl.Radio: {
      if (possibleValues) {
        return numberRadioFormFieldType(propName, array, propValue, possibleValues, formGroupSpec);
      }
      break;
    }
    case FieldInputControl.Input: {
      return numberInputFormFieldType(propName, array, propValue, formGroupSpec);
    }
    case FieldInputControl.Datetime: {
      if (!array && !Array.isArray(propValue)) {
        return dateTimeFormFieldType(propName, propValue ? Number(propValue) : null, formGroupSpec);
      }
      break;
    }
    default: {
      break;
    }
  }
  if (possibleValues) {
    if (possibleValues.length < 4) {
      return numberRadioFormFieldType(propName, array, propValue, possibleValues, formGroupSpec);
    } else {
      return numberSelectFormFieldType(propName, array, propValue, possibleValues, formGroupSpec);
    }
  }
  return numberInputFormFieldType(propName, array, propValue, formGroupSpec);
}

/**
 * Creates a form field type for a custom boolean-based form prop.
 * @param propName the custom field prop name.
 * @param control the input control specified for the custom field.
 * @param propValue the value of the field.
 * @param formGroupSpec an optional form group spec to use for this field.
 */
function getCustomBooleanFormFieldSpec(
  propName: string,
  control: FieldInputControl,
  propValue: boolean,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldType<FieldName<FieldValues>, FieldValues, FormFieldVariant> {
  switch (control) {
    case FieldInputControl.Radio: {
      return booleanRadioFormFieldType(propName, propValue ?? false, formGroupSpec);
    }
    default: {
      return checkboxFormFieldType(
        propName,
        propValue,
        formGroupSpec?.label,
        formGroupSpec?.description
      );
    }
  }
}

export function getCustomPropFormField(
  propDef: FieldDefinition,
  customComponentProp?: ComponentProp,
  formGroupSpec?: FormGroup<FormGroupAsElement | React.ElementType, React.ElementType>
): FormFieldType<FieldName<FieldValues>, FieldValues, FormFieldVariant> {
  const { id: propName, control, defaultValue, possibleValues, list } = propDef;
  const propKey = propName;
  const array = list ?? false;
  const propValue = customComponentProp?.value ?? defaultValue;
  const propDataType = propDef?.dataType ?? FieldDataType.String;
  switch (propDataType) {
    case FieldDataType.String: {
      return getCustomStringFormFieldSpec(
        propKey,
        control,
        array,
        propValue == null ? null : String(propValue),
        possibleValues,
        formGroupSpec
      );
    }
    case FieldDataType.Number: {
      return getCustomNumberFormFieldSpec(
        propKey,
        array,
        control,
        propValue == null ? null : Number(propValue),
        possibleValues,
        formGroupSpec
      );
    }
    case FieldDataType.Boolean: {
      if (!array) {
        return getCustomBooleanFormFieldSpec(
          propKey,
          control,
          propValue == null ? false : String(propValue).toLowerCase() === 'true',
          formGroupSpec
        );
      }
    }
  }
  return null;
}

/**
 * Returns the fully-qualified component name for a given template id by prefixing it with
 * the {@link CreateCustomComponent.COMPONENT_ID_PREFIX} prefix.
 *
 * @param templateId the id of the component's template.
 * @returns the fully-qualified component name for a given template id.
 */
export function getCustomComponentId(templateId: string): string {
  return templateId.includes(CreateCustomComponent.COMPONENT_ID_PREFIX)
    ? templateId
    : `${CreateCustomComponent.COMPONENT_ID_PREFIX}${templateId}`;
}

/**
 * Strips the {@link CreateCustomComponent.COMPONENT_ID_PREFIX} prefix from the given componentId.
 *
 * @param componentId the id of the component.
 * @returns the last part of the component id
 * after the {@link CreateCustomComponent.COMPONENT_ID_PREFIX} prefix has been stripped off.
 */
export function stripCustomComponentIdPrefix(componentId: string): string {
  return componentId.includes(CreateCustomComponent.COMPONENT_ID_PREFIX)
    ? componentId.slice(CreateCustomComponent.COMPONENT_ID_PREFIX.length)
    : componentId;
}

/**
 * Returns an array of entity ids for any app context entities.
 *
 * @param appContext the application context.
 * @returns an array of entity ids for any app context entities.
 */
export function getPageEntities(appContext: AppContextInterface): Array<string> {
  const pageEntities = [];
  if (appContext.contextNode?.id) {
    pageEntities.push(appContext.contextNode.id);
  }
  if (appContext.contextMessage?.id) {
    pageEntities.push(appContext.contextMessage.id);
  }
  if (appContext.contextUser?.id) {
    pageEntities.push(appContext.contextUser.id);
  }
  return pageEntities;
}

/**
 * Returns the appropriate {@link ComponentPageScope} enum mapping for the given {@link EndUserPages} enum,
 * if there is a mapping.
 *
 * @param pageId the end user page id.
 * @returns the appropriate {@link ComponentPageScope} mapping, or null if there is not a mapping.
 */
export function getComponentPageScopeForPageId(pageId: EndUserPages): ComponentPageScope {
  switch (pageId) {
    case EndUserPages.CommunityPage: {
      return ComponentPageScope.Community;
    }
    case EndUserPages.CategoryPage: {
      return ComponentPageScope.Category;
    }
    case EndUserPages.ForumBoardPage: {
      return ComponentPageScope.ForumBoard;
    }
    case EndUserPages.BlogBoardPage: {
      return ComponentPageScope.BlogBoard;
    }
    case EndUserPages.TkbBoardPage: {
      return ComponentPageScope.TkbBoard;
    }
    default: {
      return null;
    }
  }
}

/**
 * Converts an array of component prop definitions into an array of custom form fields using the default values.
 * @param props the array of custom form fields.
 */
export function getComponentCustomFormFieldsArray(
  props: Array<PropDefinition>
): Array<ComponentProp> {
  const propDefs = [];
  props.map(propDef => {
    propDefs.push({
      name: propDef.id,
      value: convertFieldDefaultValue(propDef)
    });
  });
  return propDefs;
}

/**
 * Converts custom component field properties into a GraphQL JSON Scalar that can be used as a GraphQL parameter for
 * the props.
 * @param customComponentProps the custom component props, which may be undefined if it's a newly created component.
 */
export function getComponentContextProps(
  customComponentProps: Array<ComponentProp> | undefined
): Scalars['JSON']['output'] {
  const componentProps = {};
  if (customComponentProps && customComponentProps.map) {
    customComponentProps
      .filter(
        customComponentProp =>
          isCustomPropName(customComponentProp.name) && isCustomPropValue(customComponentProp.value)
      )
      .map(customComponentProp => {
        componentProps[getDeNamespacedCustomFieldName(customComponentProp.name)] =
          customComponentProp.value;
      });
  }
  return componentProps;
}

/**
 * Returns module path for the given end-user component alias.
 * @param customComponentId the fully-qualified custom component id (should start with 'custom.widget.').
 */
export function getModulePathForCustomComponent(customComponentId: string): string {
  return customComponentId.replaceAll('.', '/');
}

/**
 * Parses the textKey out of the <li:i18n key='textKey'></li:i18n> string
 * that handlebars writes out for the {{i18: 'textKey'}} helper.
 * @param i18Expansion the string the handlebars helper wrote out with the text key.
 */
export function getTextKeyFromI18Expansion(i18Expansion: string): string | null {
  const startAtKey = i18Expansion.slice(14);
  return startAtKey.slice(0, startAtKey.indexOf("'"));
}

/**
 * Extracts the `values` attribute content from an `<li:i18n>` string and parses it into an object.
 *
 * @param i18Expansion The string representation of an `<li:i18n>` tag.
 * @returns {FormatMessageValues} An object containing the parsed key-value pairs from the `values` attribute.
 *          If the `values` attribute is missing or contains invalid JSON, an empty object is returned.
 */
export function getTextParamsFromI18Expansion(i18Expansion: string): FormatMessageValues {
  const match = i18Expansion.match(/values='(.*?)'/);
  if (!match || match.length < 2) {
    return {};
  }
  try {
    return JSON.parse(match[1]);
  } catch (error) {
    log.error('Failed to parse i18n values for custom component.', error);
  }
}
